<template>
    <v-card v-bind="floatingCardPros" :style="styleSticky" v-show="stickyWidth != 0">
        <v-card-text class="mt-n3">
            <div class="cont-title" style="float: left;">{{ floatingOptions.title }}</div>
            <div style="float: right;" class="mt-5">
                <v-btn v-if="handleBtnActive" outlined large rounded class="text-h6 text-right" color="primary" @click="doSubmit">
                    <v-icon left dark size="25">
                        mdi-check-circle
                    </v-icon>
                    저장
                </v-btn>
                <v-btn v-else large rounded class="text-h6" color="grey" @click="doSubmit">
                    <v-icon left size="25">
                        mdi-check-circle
                    </v-icon>
                    저장
                </v-btn>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import {mapState} from "vuex";

export default {
    name: "tile_floating_title",
    data: () => ({
        stickyWidth: 0,
        stickyScrollY: 0,
        floatingCardPros: { outlined: true, tile: true },
        validItem: {
            'isValid': true,
            'errorMsg': '',
            'itemA': '',
            'type': ''
        }
    }),
    computed: {
        ...mapState('svcVoiceIntro', {
            introMent: state => state.introMent,
            workOffMent: state => state.workOffMent,
            breakTimeConf: state => state.breakTimeConf,
            floatingOptions: state => state.floatingOptions
        }),
        styleSticky() {

            let optionsPixedWidth = this.floatingOptions.css.pixedWidth ? this.floatingOptions.css.pixedWidth : 0
            let optionsPixedMarginLeft = this.floatingOptions.css.pixedMarginLeft ? this.floatingOptions.css.pixedMarginLeft : 0

            let width = this.stickyWidth + optionsPixedWidth
            let border = '0px solid white'
            let bgColor = '#FFFFFF'
            let top = 90
            let marginLeft = optionsPixedMarginLeft

            if (this.stickyScrollY != 0) {
                border = '0.8px solid #b9b9b9'
                bgColor = 'rgb(244, 246, 248)'
                top = 85
            }

            return {
                'width': width + 'px',
                'height': '100px',
                'position': 'fixed',
                'top': `${top}px`,
                'z-index': 196,
                'border': border,
                'margin-left': `${marginLeft}px`,
                'background-color': bgColor
            }
        },
        handleBtnActive() {
            return this.checkValid()
        }
    },
    watch: {
        stickyScrollY() {
            if (this.stickyScrollY == 0) {
                this.floatingCardPros = { outlined: true, tile: true }
            } else {
                this.floatingCardPros = { outlined: false }
            }
        }
    },
    created() {
        this.handlePage()
    },
    mounted() {
        window.addEventListener('scroll', this.handlePage, true)
        window.addEventListener('resize', this.handlePage, true)
        this.handlePage()
    },
    updated() {
        this.handlePage()
    },
    methods: {
        handlePage() {
            let followingRow = document.querySelector('.following-row')
            if (followingRow) {
                this.stickyWidth = followingRow.clientWidth
            }
            this.stickyScrollY = window.scrollY
        },
        doSubmit() {
            if (!this.validItem.isValid) {
                this.$store.dispatch('openAlert', {
                    'message': this.validItem.errorMsg,
                    'closeCb': (va) => {
                        if (va) {
                            if (va.floatingOptions.page == 'svc_voice_intro' && va.validItem.item && Object.keys(va.validItem.item).length != 0) {
                                let errorItem = va.validItem.item
                                errorItem.error.flag = true
                                errorItem.error.msg = va.validItem.errorMsg
                                errorItem.error.type = va.validItem.type
                            }
                        }
                    },
                    'va': this
                })
                return
            }

            this.$emit('submit')
        },
        checkValid() {

            this.validItem = {'isValid': true, 'errorMsg': '', 'item': {}, 'type': ''}

            if (this.floatingOptions.page == 'svc_voice_intro') {

                // 인사말 문구 체크
                if (this.introMent.trim().length == 0) {
                    this.validItem.isValid = false
                    this.validItem.errorMsg = '인사말 문구를 입력해 주세요.'
                }

                // 영업종료 문구 체크
                if (this.validItem.isValid && this.workOffMent.trim().length == 0) {
                    this.validItem.isValid = false
                    this.validItem.errorMsg= '영업종료 문구를 입력해 주세요.'
                }

                // 휴게시간 체크
                if (this.validItem.isValid) {
                    let breakTimeUseYn = this.breakTimeConf.breakTimeUseYn
                    let breakTimeList = this.breakTimeConf.breakTimes

                    let itemA, itemB = {}

                    if (breakTimeUseYn == 'Y' && breakTimeList.length != 0) {
                        for (let i = 0; i < breakTimeList.length; i++) {

                            if (!this.validItem.isValid) {
                                break
                            }

                            itemA = breakTimeList[i]
                            let itemABreakStartTime = itemA.break_start_hour + itemA.break_start_min
                            let itemABreakEndTime = itemA.break_end_hour + itemA.break_end_min

                            if (itemABreakStartTime == itemABreakEndTime || parseInt(itemABreakStartTime) >= parseInt(itemABreakEndTime)) {
                                this.validItem.isValid = false
                                this.validItem.errorMsg = '휴게시간을 확인해 주세요.'
                                this.validItem.item = itemA
                                this.validItem.type = 'TIME'
                                break
                            }

                            if (itemA.break_ment.trim().length == 0) {
                                this.validItem.isValid = false
                                this.validItem.errorMsg = '휴게시간의 문구를 입력해 주세요'
                                this.validItem.item = itemA
                                this.validItem.type = 'MENT'
                                break
                            }

                            for (let j = 0; j < breakTimeList.length; j++) {
                                itemB = breakTimeList[j]

                                let itemBBreakStartTime = itemB.break_start_hour + itemB.break_start_min
                                let itemBBreakEndTime = itemB.break_end_hour + itemB.break_end_min

                                if (i != j && ((itemABreakStartTime >= itemBBreakStartTime && itemABreakStartTime < itemBBreakEndTime) || (itemABreakEndTime > itemBBreakStartTime && itemABreakEndTime <= itemBBreakEndTime))) {
                                    this.validItem.isValid = false
                                    this.validItem.errorMsg = '휴게시간은 서로 중복될 수 없습니다'
                                    this.validItem.item = itemA
                                    this.validItem.type = 'TIME'
                                    break
                                }
                            }

                            itemA.error = {'flag': false, 'msg': '', 'type': ''}
                        }
                    }
                }
            }

            return this.validItem.isValid
        }
    }
}
</script>

<style scoped>

</style>